import React, { FC, InputHTMLAttributes } from 'react'
import theme from '../../../common/theme'
import { ActionIcon, UnstyledButton } from '@mantine/core'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: any
  cursorPointer?: boolean
  onChange: any
  onFocus?: any
  onIconPress?: any
  isRequiredField?: boolean
  label?: string
  inputType?: string
  inputRef?: any
}

export const Input: FC<InputProps> = ({
  icon,
  cursorPointer,
  inputType,
  isRequiredField,
  onChange,
  onIconPress,
  onFocus,
  inputRef,
  label,
  ...rest
}) => {
  return (
    <div className="inputContainer">
      {inputType && inputType === 'radio' ? (
        <>
          <input
            {...rest}
            className={'radioBtn'}
            type="radio"
            ref={inputRef || null}
            onChange={onChange}
          />
          {label && (
            <span className={isRequiredField ? 'required' : ''}>{label}</span>
          )}
        </>
      ) : (
        <>
          {label && (
            <span className={isRequiredField ? 'label required' : 'label'}>
              {label}
            </span>
          )}
          <input
            {...rest}
            ref={inputRef || null}
            autoComplete="off"
            onFocus={(e) => {
              if (onFocus) onFocus(true, e)
            }}
            onBlur={(e) => {
              if (onFocus) onFocus(false, e)
            }}
            onChange={onChange}
          />
          {icon && (
            <span
              data-testid="iconInput"
              style={{
                top: label ? '40%' : '0',
                position: 'absolute',
                right: '0',
                minHeight: '2.25rem',
                width: '40px',
                height: '98%',
              }}
              // className="icon"
              onClick={() => onIconPress()}
            >
              <ActionIcon
                variant="transparent"
                color="teal"
                radius="none"
                style={{ height: '99%', width: '100%' }}
              >
                {icon}
              </ActionIcon>
            </span>
          )}
        </>
      )}

      <style jsx>{`
        .label {
          display: block;
          padding-bottom: 10px;
        }
        .radioBtn {
          width: auto;
          cursor: pointer;
        }
        .required:after {
          content: ' *';
          color: red;
        }
        .inputContainer {
          position: relative;
        }
        input {
          font-size: 0.875rem;
          border: 0.0625rem solid #ced4da;
          font-weight: 300;
          outline: none;
          color: #000;
          min-height: 2.25rem;
          width: 100%;
          text-align: left;
          border-radius: 0.25rem;
          transition: border-color 100ms ease;
          padding-left: calc(2.25rem / 3);
          padding-right: calc(2.25rem / 3);
        }
        .icon {
          cursor: ${cursorPointer ? 'pointer' : 'default'};
          text-align: center;
          position: absolute;
          right: 0;
          padding-right: 8px;
          font-size: 20px;
        }
        input:focus {
          font-size: 16px;
          border: 2px solid ${theme.colors.brandBlack};
        }
      `}</style>
    </div>
  )
}
